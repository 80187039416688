<script setup>
import Layout from "../../layouts/main.vue";
import MethodService from "../../service/MethodService";
import mushroom from "cem-primary-api";
import { onMounted, reactive, ref } from "vue";
import DataForm from "./dataFormTable";
import { FormInstance } from "element-plus";
import { useRouter, useRoute } from "vue-router";
const rolesAccount = ref("");
const listDBType = DataForm.dbType;
// ngôn ngữ -> bắt buộc
import en from "element-plus/lib/locale/lang/en";
import vi from "element-plus/lib/locale/lang/vi";

// lấy ngôn ngữ từ pinia store
import { langWeb } from "../../stores/lang";
import toastr from "toastr";
import Swal from "sweetalert2";
import LanguageService from "../../service/LanguageService";

const lang_web = langWeb();
const lang = ref(lang_web.showLang.toLocaleUpperCase());
const ruleFormRef = ref(FormInstance);
const validForm = reactive({ value: DataForm.tableRules.dataSearch.valid });
const tableRules = reactive(MethodService.copyObject(DataForm.tableRules));
const dataTables = reactive({ value: [] }); // data bảng
const dataService = reactive({ value: [] });
let loading = ref(true); // loading bảng
const router = useRouter();
const route = useRoute();
const listSplit = ["none", "year", "month", "day"];
const typeProbeBras = ["probe", "bras"];

// function
// lấy về danh sách bảng
const getTableConfig = async () => {
  let dataFilter = {
    filters: tableRules.filters,
    fields: "id,type,db_layer_type,name,split_type,service_id",
    limit: 100,
    offset: tableRules.offset,
    sort: tableRules.sort,
    showFormSearch: tableRules.showFormSearch,
  };
  router
    .replace({
      name: "TableConfig",
      query: {
        ...dataFilter,
      },
    })
    .catch(() => {});
  try {
    const response = await mushroom.table_config.listAsync(dataFilter, {
      enabledCache: false,
    });
    if (response.result) {
      loading.value = false;
      let res = await changeData(response.result);
      dataTables.value = res;
      tableRules.total = response.meta.total;
      tableRules.page = response.meta.offset / response.meta.limit + 1;
    }
  } catch (e) {
    console.error("Có lỗi: %o", e);
    MethodService.showError(e.code);
  }
};

const changeData = async (data) => {
  const item = data.filter((x) => x.service_id).map((x) => x.service_id);

  if (item.length > 0) {
    try {
      const response = await mushroom.service.listAsync({
        filters: "id:in:" + item.join(","),
      });
      if (response.result) {
        data.forEach((service) => {
          if (service.service_id) {
            response.result.forEach((x) => {
              if (x.id === service.service_id) {
                service.service_name = x.name;
              }
            });
          }
        });
      }
    } catch (e) {
      console.error("Có lỗi: %o", e);
    }
  }
  return data;
};

const getServiceName = async () => {
  try {
    const response = await mushroom.table_config.listAsync();
    console.log("Các table_config thỏa mãn: %o, info: %o", response);
    const item = response.result
      .filter((x) => x.service_id)
      .map((x) => x.service_id);
    try {
      const dataNew = await mushroom.service.listAsync({
        filters: "id:in:" + item.join(","),
      });
      dataService.value = dataNew.result;
    } catch (e) {
      console.error("Có lỗi: %o", e);
    }
  } catch (e) {
    console.error("Có lỗi: %o", e);
  }
};

// hiển thị form tìm kiếm
const fn_showFormTimKiem = () => {
  tableRules.showFormSearch = !tableRules.showFormSearch;
  let query = Object.assign({}, route.query);
  query.showFormSearch = tableRules.showFormSearch;
  router.push({
    name: "TableConfig",
    query: query,
  });
};

const handleService = (event) => {
  if (!event) return;
  tableRules.dataSearch.value["service_id"] = tableRules.dataSearch.value[
    "service_id"
  ]
    ? tableRules.dataSearch.value["service_id"].trim()
    : "";
};

// tìm kiếm
const fn_TimKiem = (formEl) => {
  if (!formEl) return;
  formEl.validate(async (valid) => {
    if (valid) {
      loading.value = true;
      tableRules.dataSearch.value["name"] = tableRules.dataSearch.value["name"]
        ? tableRules.dataSearch.value["name"].trim()
        : "";
      tableRules.dataSearch.value["split_type"] = tableRules.dataSearch.value[
        "split_type"
      ]
        ? tableRules.dataSearch.value["split_type"].trim()
        : "";
      tableRules.dataSearch.value["service_id"] = tableRules.dataSearch.value[
        "service_id"
      ]
        ? tableRules.dataSearch.value["service_id"].trim()
        : "";

      // handleSplit();
      handleService();

      tableRules.filters = MethodService.filterTable(
        JSON.stringify(tableRules.dataSearch)
      );
      tableRules.offset = 0;
      tableRules.page = 1;
      await getTableConfig();
    } else return false;
  });
};

const fn_tableSortChange = async (column, tableSort) => {
  tableSort = tableRules;
  MethodService.tableSortChange(column, tableSort);
  await getTableConfig();
};

const handleClick = (id) => {
  router.push({
    name: "UpdateTable",
    query: { id: id },
  });
};

const fn_CreateTable = () => {
  router.push({ name: "CreateTable" });
};

const deleteTable = async (id) => {
  if (!id) return;
  Swal.fire({
    title: LanguageService[lang.value]?.t_warning ?? "t_warning",
    text: LanguageService[lang.value]?.t_table_delete ?? "t_table_delete",
    icon: "warning",
    confirmButtonColor: "#f46a6a",
    confirmButtonText: LanguageService[lang.value].swal_accept,
    cancelButtonText: LanguageService[lang.value].swal_cancel,
    showCancelButton: true,
    allowOutsideClick: false,
  }).then(async (result) => {
    if (result.isConfirmed) {
      try {
        const response = await mushroom.table_config.deleteAsync({
          id: id, // required
        });
        if (response.result)
          toastr.success(
            LanguageService[lang.value]?.toastr_delete_table_success ??
              "toastr_delete_table_success"
          );
        await getTableConfig();
      } catch (e) {
        toastr.error(
          LanguageService[lang.value]?.toastr_delete_table_fail ??
            "toastr_delete_table_fail"
        );
        console.error("Có lỗi: %o", e);
      }
    }
  });
};

const fn_hashUrl = () => {
  if (route.query.limit) {
    tableRules.limit = parseInt(route.query.limit);
  }
  if (route.query.page) {
    tableRules.page = parseInt(route.query.page);
  }
  if (route.query.limit && route.query.page) {
    tableRules.offset = (tableRules.page - 1) * tableRules.limit;
  }
  if (route.query.sort) {
    tableRules.sort = route.query.sort;
    tableRules.defaultSort[0] = tableRules.sort;
  }
  if (tableRules.sort.indexOf("-") != -1) {
    tableRules.defaultSort[1] = "descending";
  } else {
    tableRules.defaultSort[1] = "ascending";
  }
  if (route.query.filters) {
    tableRules.filters = route.query.filters;
    // string to object
    tableRules.dataSearch.value = MethodService.stringToObject(
      tableRules.filters
    );
  }
  if (route.query.showFormSearch) {
    tableRules.showFormSearch = route.query.showFormSearch;
  }
};

onMounted(async () => {
  const response = await mushroom.$auth.meAsync();
  rolesAccount.value = response.result.roles[0];
  getServiceName();
  fn_hashUrl();
  await getTableConfig();
});
</script>

<template>
  <Layout>
    <div class="card">
      <div class="card-header" style=" display: flex, justify: space-between ">
        <h3 class="text-uppercase mb-0">{{ $t("t-list-table") }}</h3>
        <div class="text-end">
          <div class="btn-header">
            <button
              class="btn btn-soft-secondary btn-border"
              style="margin-right: 8px"
              @click="fn_showFormTimKiem()"
            >
              <i class="ri-search-line"></i>
              {{ $t("t-search") }}
            </button>
            <button
              class="btn btn-secondary btn-border"
              @click="fn_CreateTable"
              v-if="rolesAccount === 'Admin'"
            >
              <i class="ri-add-circle-line"></i>
              {{ $t("t-add") }}
            </button>
          </div>
        </div>
      </div>

      <div class="card-body" style="padding: 0 0 15px 0">
        <div class="main-body">
          <div class="main-body-search mx-3 mb-3">
            <b-collapse
              v-model="tableRules.showFormSearch"
              id="myFormSearch"
              class="b-shadow"
            >
              <b-card>
                <el-config-provider
                  :locale="lang_web.showLang == 'vi' ? vi : en"
                >
                  <el-form
                    ref="ruleFormRef"
                    :model="tableRules.dataSearch.value"
                    :rules="validForm.value"
                    label-position="top"
                    @submit.prevent
                  >
                    <b-row>
                      <b-col>
                        <el-form-item
                          :label="`${$t('t-table-name')}`"
                          prop="name"
                        >
                          <el-input
                            clearable
                            v-model="tableRules.dataSearch.value['name']"
                            :placeholder="`${$t('t-place-table-name')}`"
                          ></el-input>
                        </el-form-item>
                      </b-col>
                      <b-col>
                        <el-form-item :label="`${$t('t-db-layer-type')}`">
                          <el-select
                            v-model="
                              tableRules.dataSearch.value['db_layer_type']
                            "
                            filterable
                            clearable
                            :placeholder="`${$t('t-place-db-layer-type')}`"
                            :no-match-text="$t('t-match-data')"
                            :no-data-text="$t('t-no-data')"
                          >
                            <el-option
                              v-for="item in listDBType"
                              :key="item.value"
                              :label="item.label"
                              :value="item.value"
                            />
                          </el-select>
                        </el-form-item>
                      </b-col>
                      <b-col>
                        <el-form-item :label="`${$t('t-type-probe-bras')}`">
                          <el-select
                            v-model="tableRules.dataSearch.value['type']"
                            filterable
                            clearable
                            :placeholder="`${$t('t-place-type-probe-bras')}`"
                            :no-match-text="$t('t-match-data')"
                            :no-data-text="$t('t-no-data')"
                          >
                            <el-option
                              v-for="item in typeProbeBras"
                              :key="item"
                              :label="item"
                              :value="item"
                            />
                          </el-select>
                        </el-form-item>
                      </b-col>
                      <b-col>
                        <el-form-item :label="`${$t('t-split-type')}`">
                          <el-select
                            v-model="tableRules.dataSearch.value['split_type']"
                            filterable
                            clearable
                            :placeholder="`${$t('t-choose-split-type')}`"
                            :no-match-text="$t('t-match-data')"
                            :no-data-text="$t('t-no-data')"
                          >
                            <el-option
                              v-for="item in listSplit"
                              :key="item"
                              :label="item"
                              :value="item"
                            />
                          </el-select>
                        </el-form-item>
                      </b-col>
                      <b-col>
                        <el-form-item :label="`${$t('t-service')}`">
                          <el-select
                            v-model="tableRules.dataSearch.value['service_id']"
                            filterable
                            clearable
                            :placeholder="`${$t('t-choose-service')}`"
                            :no-match-text="$t('t-match-data')"
                            :no-data-text="$t('t-no-data')"
                            @change="handleService"
                          >
                            <el-option
                              v-for="item in dataService.value"
                              :key="item.id"
                              :label="item.name"
                              :value="item.id"
                            />
                          </el-select>
                        </el-form-item>
                      </b-col>
                    </b-row>
                    <div class="text-center">
                      <a
                        class="btn btn-soft-secondary btn-border mx-3"
                        style="width: 80px"
                        @click="fn_showFormTimKiem()"
                      >
                        {{ $t("t-hide") }}
                      </a>
                      <button
                        class="btn btn-secondary bg-gradient btn-border waves-effect waves-light"
                        style="width: 100px"
                        native-type="submit"
                        @click="fn_TimKiem(ruleFormRef)"
                      >
                        {{ $t("t-search") }}
                      </button>
                    </div>
                  </el-form>
                </el-config-provider>
              </b-card>
            </b-collapse>
          </div>
          <el-config-provider :locale="lang_web.showLang == 'vi' ? vi : en">
            <el-table
              size="small"
              border
              stripe
              :data="dataTables.value"
              style="width: 100%, ov"
              v-loading="loading"
              :empty-text="$t('t-no-data')"
              @sort-change="fn_tableSortChange"
              :default-sort="{
                prop: tableRules.defaultSort[0],
                order: tableRules.defaultSort[1],
              }"
            >
              <el-table-column label="STT" width="60" align="center">
                <template #default="scope">
                  <div>
                    {{ scope.$index + tableRules.offset + 1 }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                prop="name"
                :label="`${$t('t-table-name')}`"
                min-width="200"
                align="center"
                :sortable="true"
              ></el-table-column>
              <el-table-column
                prop="type"
                :label="`${$t('t-type-probe-bras')}`"
                align="center"
                width="200"
              ></el-table-column>
              <el-table-column
                :label="`${$t('t-db-layer-type')}`"
                align="center"
                width="120"
              >
                <template #default="scope">
                  {{
                    scope.row.db_layer_type === "tong_hop"
                      ? "Tổng hợp"
                      : "Chi tiết"
                  }}
                </template>
              </el-table-column>
              <el-table-column
                prop="split_type"
                :label="`${$t('t-split-type')}`"
                align="center"
                width="150"
                :sortable="true"
              ></el-table-column>
              <el-table-column
                prop="service_name"
                :label="`${$t('t-service-name')}`"
                align="center"
                min-width="200"
              ></el-table-column>
              <el-table-column
                :label="`${$t('t-show-district')}`"
                width="140"
                align="center"
              >
                <template #default="scope">
                  <div class="btn-group-thao-tac">
                    <el-tooltip
                      class="box-item"
                      effect="light"
                      :content="$t('t-edit')"
                      placement="top"
                    >
                      <button
                        type="button"
                        class="btn btn-soft-success waves-effect waves-light btn-sm"
                        @click="handleClick(scope.row.id)"
                        :disabled="rolesAccount === 'User'"
                      >
                        <i class="ri-edit-box-line"></i>
                      </button>
                    </el-tooltip>
                    <el-tooltip
                      class="box-item"
                      effect="light"
                      :content="$t('t-delete')"
                      placement="top"
                    >
                      <button
                        type="button"
                        class="btn btn-soft-danger waves-effect waves-light btn-sm"
                        @click="deleteTable(scope.row.id)"
                        :disabled="rolesAccount === 'User'"
                      >
                        <i class="ri-delete-bin-6-line"></i>
                      </button>
                    </el-tooltip>
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </el-config-provider>
        </div>
      </div>
    </div>
  </Layout>
</template>

<style scoped>
a {
  color: unset;
}
.el-table {
  height: calc(100vh - 200px);
}
</style>
